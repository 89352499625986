<script>
    import { getContext } from 'svelte';
    const settingsContext = getContext('settingsContext');

    const { data } = settingsContext;

    // prop from SettingsSecurity.svelte
    export let type;
</script>

{#each data['devices'] as device}
    {#if (type == 'verified' && device.verified_at) || (type == 'unverified' && device.verified_at)}
        <span class="list-link d-flex flex-column pointer-cursor rounded pb-2 pl-3 pt-2">
            <div class="d-flex flex-row-no-rtl justify-content-between">
                <div>
                    <h6 class="d-flex align-items-center mb-1">
                        <span data-toggle="tooltip" data-placement="top" title="{device.device_type}">
                            <!-- @switch($device->device_type)
                                @case('Desktop')
                                @include('elements.icon',['icon'=>'desktop-outline','classes'=>'mr-2'])
                                @break
                                @case('Mobile')
                                @include('elements.icon',['icon'=>'phone-portrait-outline','classes'=>'mr-2'])
                                @break
                                @case('Tablet')
                                @include('elements.icon',['icon'=>'tablet-portrait-outline','classes'=>'mr-2'])
                                @break
                            @endswitch -->
                        </span>
                        {device.browser} on {device.platform}
                    </h6>
                    <small class="text-muted">Created at: {device.created_at}</small>
                </div>
                <div class="d-flex justify-content-between align-items-center pr-3">
                    <span
                        class="h-pill h-pill-accent rounded"
                        onclick="PrivacySettings.showDeviceDeleteConfirmation('{device.signature}')"
                    >
                        <!-- @include('elements.icon',['icon'=>'close-outline']) -->
                    </span>
                </div>
            </div>
        </span>
    {/if}
{/each}
